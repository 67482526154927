import { ref } from 'vue';
// temporarily no-duplicates until we don't receive others images
// eslint-disable-next-line import/no-duplicates
import GO from '@/assets/images/products/GO.jpg';
// eslint-disable-next-line import/no-duplicates
import CFC from '@/assets/images/products/GO.jpg';
import I_REC from '@/assets/images/products/I_REC.jpg';
import F_GAS from '@/assets/images/products/F_GAS.jpg';
import BIOMETHANE_HEATING from '@/assets/images/products/BIOMETHANE_HEATING.jpg';
import BIOMETHANE_TRANSPORT from '@/assets/images/products/BIOMETHANE_TRANSPORT.jpg';
import EU_ETS from '@/assets/images/products/EU_ETS.jpg';
import VCM from '@/assets/images/products/VCM.jpg';
import HBE from '@/assets/images/products/HBE.jpg';
import THG_Q from '@/assets/images/products/THG_Q.jpg';
import RTFC from '@/assets/images/products/RTFC.jpg';
import BIOFUELS from '@/assets/images/products/BIOFUELS.jpg';
import PPA from '@/assets/images/products/PPA.jpg';
import REFRIGERANT_GAS from '@/assets/images/products/REFRIGERANT_GAS.jpg';
import RTC from '@/assets/images/products/RTC.jpg';
import RECS from '@/assets/images/products/RECS.jpg';
import MANAGEMENT from '@/assets/images/products/Management.jpg';

export const images = ref({
  GO,
  BIOMETHANE_HEATING,
  BIOMETHANE_TRANSPORT,
  EU_ETS,
  VCM,
  HBE,
  THG_Q,
  RTFC,
  BIOFUELS,
  F_GAS,
  PPA,
  CFC,
  I_REC,
  REFRIGERANT_GAS,
  RTC,
  RECS,
  MANAGEMENT,
});
