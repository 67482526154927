import { RouterList } from '@/types/base-types';
import { getTranslationTerms } from '@/utils/composable/localeHelper';

const getProductTerms = getTranslationTerms.bind(null, 'products');
const getPageTerms = getTranslationTerms.bind(null, 'pages');

export const MAIN_ROUTES: RouterList = Object.freeze({
  DASHBOARD: {
    title: getPageTerms('dashboard', 'routeTitle'),
    name: 'dashboard',
    path: '/dashboard',
    mainRoute: true,
  },
  BUTTONS: {
    title: getPageTerms('buttons', 'routeTitle'),
    name: 'buttons',
    path: '/buttons',
    mainRoute: true,
  },
  AUTH: {
    title: getPageTerms('auth', 'routeTitle'),
    name: 'auth',
    path: '/auth',
    mainRoute: true,
  },
  RESET_PASSWORD: {
    title: getPageTerms('resetPassword', 'routeTitle'),
    name: 'resetPassword',
    path: '/passwordReset',
    mainRoute: true,
  },
  PRODUCT_SWITCHER: {
    title: getPageTerms('productSwitcher', 'routeTitle'),
    name: 'productSwitcher',
    path: '/product-switcher',
    mainRoute: true,
  },
  RFQ: {
    title: getPageTerms('rfq', 'routeTitle'),
    name: 'rfq',
    path: '/dashboard/:productSlug/rfq',
    mainRoute: true,
  },
  RFQ_THANKS: {
    title: getPageTerms('rfqThanks', 'routeTitle'),
    name: 'rfqThanks',
    path: '/dashboard/:productSlug/rfq/thank-you',
    mainRoute: true,
  },
  RFQ_CANCELLED: {
    title: getPageTerms('rfqCancelled', 'routeTitle'),
    name: 'rfqCancelled',
    path: '/dashboard/:productSlug/rfq/cancelled',
    mainRoute: true,
  },
  RFQ_DETAILS: {
    title: getPageTerms('rfq', 'detailsRouteTitle'),
    name: 'rfqDetails',
    path: '/dashboard/:productSlug/rfq/:id',
    mainRoute: true,
  },
  RFQ_HISTORY: {
    title: getPageTerms('rfqHistoryPage', 'routeTitle'),
    name: 'rfqHistoryPage',
    path: '/dashboard/:productSlug/rfq/history',
    mainRoute: true,
  },
  CALENDAR: {
    title: getPageTerms('calendar', 'routeTitle'),
    name: 'calendar',
    path: '/calendar',
    mainRoute: true,
  },
  GLOSSARY: {
    title: getPageTerms('glossary', 'routeTitle'),
    name: 'glossary',
    path: '/glossary',
    mainRoute: true,
  },
  SETTINGS: {
    title: getPageTerms('settings', 'routeTitle'),
    name: 'settings',
    path: '/settings',
    mainRoute: true,
  },
  CHAT: {
    title: getPageTerms('chat', 'routeTitle'),
    name: 'chat',
    path: '/chat',
    mainRoute: true,
  },
  NOTIFICATIONS: {
    title: getPageTerms('notifications', 'routeTitle'),
    name: 'notifications',
    path: '/notifications',
    mainRoute: true,
  },
  NOT_FOUND: {
    title: getPageTerms('not_found', 'routeTitle'),
    name: '404',
    path: '/404',
    mainRoute: true,
  }, // temp redirecting to dashboard page until 404 page is designed
  INVITE: {
    title: getPageTerms('invite', 'routeTitle'),
    name: 'invite',
    path: '/account/invite',
    mainRoute: true,
  },
  API_PROFILE: {
    title: getPageTerms('apiProfile', 'routeTitle'),
    name: 'apiProfile',
    path: '/api-profile',
    mainRoute: true,
  },
  IOI_DETAIL: {
    title: getPageTerms('indicationOfInterest', 'routeTitle'),
    name: 'indicationdsOfInterestDetail',
    path: '/:productSlug/trading-platform/indication-of-interest/:id',
    mainRoute: true,
  },
  IOI_OVERVIEW: {
    title: getPageTerms('indicationOfInterest', 'routeTitle'),
    name: 'indicationdsOfInterestOverview',
    path: '/:productSlug/trading-platform/indication-of-interest',
    mainRoute: true,
  },
  ENERGY_EXCHANGE_TRADE: {
    title: getPageTerms('energyExchange', 'routeTitle'),
    name: 'energyExchangeTrade',
    path: '/:productSlug/trading-platform/trade',
    mainRoute: true,
  },
  ENERGY_EXCHANGE_MANAGEMENT: {
    title: getPageTerms('energyExchange', 'routeTitle'),
    name: 'energyExchangeManagement',
    path: '/:productSlug/trading-platform/trade-management',
    mainRoute: true,
  },
  ENERGY_EXCHANGE_ORDER_DETAIL: {
    title: getPageTerms('energyExchange', 'routeTitle'),
    name: 'energyExchangeOrderDetail',
    path: '/:productSlug/trading-platform/trade/:orderId',
    mainRoute: false,
  },
  ENERGY_EXCHANGE_ORDER_DETAIL_MANAGEMENT: {
    title: getPageTerms('energyExchange', 'routeTitle'),
    name: 'energyExchangeOrderDetailManagement',
    path: '/:productSlug/trading-platform/trade-management/:orderId',
    mainRoute: false,
  },
  ENERGY_EXCHANGE_CLIENT: {
    title: getPageTerms('energyExchange', 'routeTitle'),
    name: 'energyExchangeClient',
    path: '/trading-platform',
    mainRoute: true,
  },
  PORTAL_FEATURES: {
    title: 'Portal Features',
    name: 'portalFeatures',
    path: '/portal-features',
    mainRoute: true,
  },
  PRODUCT_DETAILS: {
    title: getPageTerms('productDetails', 'routeTitle'),
    name: 'productDetails',
    path: '/products/:abbr',
    mainRoute: true,
  },
});

export const COMMON_SIDE_BAR_ROUTES: RouterList = Object.freeze({
  DOCUMENT_LIB: {
    title: getProductTerms('doclib', 'name'),
    name: 'doclib',
    path: '/dashboard/:productSlug/document-lib',
    mainRoute: false,
  },
  DOCUMENT_LIB_CATEGORIES: {
    title: getProductTerms('doclibCategories', 'name'),
    name: 'doclibCategories',
    path: '/dashboard/:productSlug/:docsTypeSlug(product-documents)/:categorySlug',
    mainRoute: false,
  },
  TRADE_CATEGORIES: {
    title: getProductTerms('tradeDocuments', 'name'),
    name: 'tradeDocuments',
    path: '/dashboard/:productSlug/:docsTypeSlug(my-documents|client-documents)/:categorySlug',
    mainRoute: false,
  },
  BLOG: {
    title: getProductTerms('blog', 'name'),
    name: 'marketInsights',
    path: '/market-insights',
    mainRoute: false,
  },
  PRICES: {
    title: getPageTerms('prices', 'routeTitle'),
    name: 'prices',
    path: '/dashboard/:productSlug/prices',
    mainRoute: true,
  },
  VIEW_BLOG_POST: {
    title: getProductTerms('viewBlog', 'name'),
    name: 'viewMarketInsights',
    path: '/market-insights/:postId',
    mainRoute: false,
  },
  NEWSLETTER: {
    title: getProductTerms('newsletter', 'name'),
    name: 'newsletter',
    path: '/dashboard/:productSlug/newsletter',
    mainRoute: false,
  },
  VIEW_NEWSLETTER: {
    title: getProductTerms('viewNewsletter', 'name'),
    name: 'viewNewsletter',
    path: '/dashboard/:productSlug/newsletter/:postId',
    mainRoute: false,
  },
  EDIT_NEWSLETTER: {
    title: getProductTerms('editNewsletter', 'name'),
    name: 'editNewsletter',
    path: '/dashboard/:productSlug/newsletter/edit/:postId',
    mainRoute: false,
  },
  CREATE_NEWSLETTER: {
    title: getProductTerms('createBlog', 'name'),
    name: 'createNewsletter',
    path: '/dashboard/:productSlug/newsletter/create-newsletter',
    mainRoute: false,
  },
  RECYCLE_BIN: {
    title: getProductTerms('recycleBin', 'name'),
    name: 'recycleBin',
    path: '/dashboard/:productSlug/recycleBin',
    mainRoute: false,
  },
  WEBINARS: {
    title: getProductTerms('webinarsList', 'name'),
    name: 'Webinars',
    path: '/webinars',
    mainRoute: false,
  },
  VIEW_WEBINAR: {
    title: getProductTerms('viewWebinar', 'name'),
    name: 'ViewWebinar',
    path: '/webinars/:webinarId',
    mainRoute: false,
  },
  CLIENT_DOCUMENTS: {
    title: getPageTerms('clientDocuments', 'routeTitle'),
    name: 'clientDocuments',
    path: '/client-documents',
    mainRoute: true,
  },
  MY_DOCUMENTS: {
    title: getPageTerms('myDocuments', 'routeTitle'),
    name: 'myDocuments',
    path: '/my-documents',
    mainRoute: true,
  },
  TRANSACTIONS: {
    title: getProductTerms('transactions', 'name'),
    name: 'transactions',
    path: '/dashboard/:productSlug/transactions',
    mainRoute: false,
  },
  TRANSACTION_DETAILS: {
    title: getProductTerms('transactions', 'name'),
    name: 'transaction_details',
    path: '/dashboard/:productSlug/transactions/:id',
    mainRoute: false,
  },
  COMMODITY_VOLUMES: {
    title: getPageTerms('commodityVolumes', 'routeTitle'),
    name: 'commodity_volumes',
    path: '/dashboard/:productSlug/commodity-volumes',
    mainRoute: false,
  },
});

export const USER_ROLE_ROUTES: RouterList = Object.freeze({
  CLIENTS: {
    title: getPageTerms('clients', 'routeTitle'),
    name: 'clients',
    path: '/clients',
    mainRoute: false,
  },
  CLIENT_NEW: {
    title: getPageTerms('clientsNew', 'routeTitle'),
    name: 'clientsNew',
    path: '/clients/new',
    mainRoute: false,
  },
  APPROVE_CLIENT: {
    title: getPageTerms('approveClient', 'routeTitle'),
    name: 'approveClient',
    path: '/clients/approve/:companyId',
    mainRoute: false,
  },
  CLIENT_PROFILE: {
    title: getPageTerms('clientsProfile', 'routeTitle'),
    name: 'clientsProfile',
    path: '/clients/profile',
    mainRoute: false,
  },
  CLIENT_COMPANY_PROFILE: {
    title: getPageTerms('clientsCompanyProfile', 'routeTitle'),
    name: 'clientsCompanyProfile',
    path: '/clients/companyProfile',
    mainRoute: false,
  },
  CLIENT_COMPANY_PROFILE_DETAIL: {
    title: getPageTerms('clientsCompanyProfile', 'routeTitle'),
    name: 'clientsCompanyProfile',
    path: '/clients/companyProfile/:id',
    mainRoute: false,
  },
  CLIENTS_CREATE_SUBENTITY: {
    title: getPageTerms('createSubEntity', 'routeTitle'),
    name: 'createSubEntity',
    path: '/clients/:companyId/create-subentity',
    mainRoute: false,
  },
  CLIENTS_SUBENTITY_DETAILS: {
    title: getPageTerms('subEntityDetails', 'routeTitle'),
    name: 'subEntityDetails',
    path: '/subentity',
    mainRoute: false,
  },
  SUSTAINABILITY_ADVISORS: {
    title: getPageTerms('sAdvisors', 'routeTitle'),
    name: 'sAdvisors',
    path: '/sustainability-advisors',
    mainRoute: false,
  },
  SUSTAINABILITY_ADVISORS_NEW: {
    title: getPageTerms('sAdvisorsNew', 'routeTitle'),
    name: 'sAdvisorsNew',
    path: '/sustainability-advisors/new',
    mainRoute: false,
  },
  SUSTAINABILITY_ADVISOR_PROFILE: {
    title: getPageTerms('sAdvisorProfile', 'routeTitle'),
    name: 'sAdvisorProfile',
    path: '/sustainability-advisors',
    mainRoute: true,
  },
  BUSINESS_SUPPORTS: {
    title: getPageTerms('bSupps', 'routeTitle'),
    name: 'bSupps',
    path: '/business-supports',
    mainRoute: false,
  },
  BUSINESS_SUPPORT_PROFILE: {
    title: getPageTerms('bSuppProfile', 'routeTitle'),
    name: 'bSuppProfile',
    path: '/business-supports/:profileId',
    mainRoute: false,
  },
  COMPANY: {
    title: getPageTerms('userCompany', 'routeTitle'),
    name: 'userCompany',
    path: '/company',
    mainRoute: false,
  },
});

export const DASHBOARD_NESTED_ROUTES: RouterList = Object.freeze({
  PPA: {
    title: getProductTerms('ppa', 'name'),
    name: 'ppa',
    path: '/dashboard/ppa',
    mainRoute: true,
  },
  GO: {
    title: getProductTerms('go', 'name'),
    name: 'go',
    path: '/dashboard/go',
    mainRoute: true,
  },
  MARKET_DATA: {
    title: getProductTerms('marketdata', 'name'),
    name: 'marketdata',
    path: '/dashboard/ppa/market-data',
    mainRoute: false,
  },
  ADD_WIND_FARM: {
    title: getProductTerms('addwindfarm', 'name'),
    name: 'addwindfarm',
    path: '/dashboard/ppa/add-wind-farm',
    mainRoute: false,
  },
  ADD_SOLAR_FARM: {
    title: getProductTerms('addsolarfarm', 'name'),
    name: 'addsolarfarm',
    path: '/dashboard/ppa/add-solar-farm',
    mainRoute: false,
  },
  GGC: {
    title: getProductTerms('ggc', 'name'),
    name: 'ggc',
    path: '/dashboard/ggc',
    mainRoute: true,
  },
  FGAS: {
    title: getProductTerms('fgas', 'name'),
    name: 'fgas',
    path: '/dashboard/f-gas',
    mainRoute: true,
  },
  GPA: {
    title: getProductTerms('gpa', 'name'),
    name: 'gpa',
    path: '/dashboard/gpa',
    mainRoute: true,
  },
  GGC_NO_SUPPORT: {
    title: 'GGC_NO_SUPPORT',
    name: 'GGC_NO_SUPPORT',
    path: '/dashboard/ggc-no-support',
    mainRoute: true,
  },
  EU_ETS: {
    title: getProductTerms('EU_ETS', 'name'),
    name: 'eu_ets',
    path: '/dashboard/eu-ets',
    mainRoute: true,
  },
  VCM: {
    title: getProductTerms('vcm', 'name'),
    name: 'vcm',
    path: '/dashboard/vcm',
    mainRoute: true,
  },
  HBE: {
    title: getProductTerms('hbe', 'name'),
    name: 'hbe',
    path: '/dashboard/hbe',
    mainRoute: true,
  },
  THG_Q: {
    title: getProductTerms('thg_q', 'name'),
    name: 'thg_q',
    path: '/dashboard/thg-q',
    mainRoute: true,
  },
  RTFC: {
    title: getProductTerms('rtfc', 'name'),
    name: 'rtfc',
    path: '/dashboard/rtfc',
    mainRoute: true,
  },
  BIOFUELS: {
    title: getProductTerms('biofuels', 'name'),
    name: 'biofuels',
    path: '/dashboard/biofuels',
    mainRoute: true,
  },
  F_GAS: {
    title: getProductTerms('f_gas', 'name'),
    name: 'f_gas',
    path: '/dashboard/f-gas',
    mainRoute: true,
  },
  CFC: {
    title: getProductTerms('cfc', 'name'),
    name: 'cfc',
    path: '/dashboard/cfc',
    mainRoute: true,
  },
  I_REC: {
    title: getProductTerms('i_rec', 'name'),
    name: 'i_rec',
    path: '/dashboard/i-rec',
    mainRoute: true,
  },
});

export const GO_BACK_ROUTES = Object.freeze({
  DASHBOARD: MAIN_ROUTES.DASHBOARD,
  CLIENTS: USER_ROLE_ROUTES.CLIENTS,
  COMPANY: USER_ROLE_ROUTES.COMPANY,
  CLIENT_COMPANY: USER_ROLE_ROUTES.CLIENT_COMPANY_PROFILE,
  BLOG: COMMON_SIDE_BAR_ROUTES.BLOG,
  DOCUMENT_LIB: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB,
  RFQ: MAIN_ROUTES.RFQ,
  RFQ_HISTORY: MAIN_ROUTES.RFQ_HISTORY,
  SUSTAINABILITY_ADVISORS: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS,
  BUSINESS_SUPPORTS: USER_ROLE_ROUTES.BUSINESS_SUPPORTS,
  DASHBOARD_DEFAULT_VIEW: {
    title: getPageTerms('dashboard', 'routeTitle'),
    name: 'dashboardDefaultView',
    path: '/dashboard',
    mainRoute: true,
  },
});
