<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { logErrorMessages } from '@vue/apollo-util';
import BaseLoader from '@/components/base/BaseLoader.vue';
import ClickButton from '@/components/base/ClickButton.vue';
import {
  useCreateProductAccessRequestMutation,
  useGetAccountQuery,
  useProductsQuery,
} from '@/types/graphql';
import { useGetProductsInfoQuery } from '@/types/graphql/squidex';
import { useNotificationStore, useUserStore } from '@/store';
import { NotificationType } from '@/types/notification';
import { t } from '@/utils/composable/localeHelper';
import ProductCard from '@/components/products/ProductCard.vue';
import { GetAccountDocument, GetAccountQuery } from '@/types/graphql/auth';
import ViewPostContent from '@/views/newsletterModule/ViewPostContent.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { images } from '@/utils/images';

export default defineComponent({
  name: 'ProductDetails',
  components: {
    BaseLoader,
    ClickButton,
    ProductCard,
    ViewPostContent,
    BaseIcon,
  },
  props: {
    productAbbr: {
      type: String,
      required: true,
    },
    popupMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const userStore = useUserStore();
    const productAbbr = ref(props.productAbbr);
    const { addNotification } = useNotificationStore();

    const { result: squidexProductsResult, loading: loadingProductInfo } = useGetProductsInfoQuery(
      () => ({
        filter: `data/abbreviation/iv eq '${productAbbr.value}'`,
      }),
    );

    const { result: accountResult, loading: loadingAccount } = useGetAccountQuery(
      {
        id: userStore.userProfile.id as string,
      },
      { fetchPolicy: 'cache-first' },
    );

    const { result: allProductsResult } = useProductsQuery({ fetchPolicy: 'cache-and-network' });

    const currentProduct = computed(() => {
      const squidexProduct =
        squidexProductsResult.value?.queryProductsContentsWithTotal?.items?.[0];
      if (!squidexProduct) return null;

      const selectedProduct = allProductsResult.value?.products?.edges?.find(
        (product) => product?.node?.abbreviation === squidexProduct.data?.abbreviation?.iv,
      )?.node;

      return {
        id: selectedProduct?.id,
        abbr: squidexProduct.data?.abbreviation?.iv ?? '',
        name: squidexProduct.data?.name?.iv ?? '',
        mainText: squidexProduct.data?.mainText?.iv ?? null,
      };
    });

    const availableProductIds = computed(
      () =>
        new Set(
          accountResult.value?.account?.companyRepresentativeProducts?.edges
            ?.map((item) => item?.node?.id)
            .filter(Boolean) ?? [],
        ),
    );

    const pendingProductIds = computed(
      () =>
        new Set(
          accountResult.value?.account?.productAccessRequests?.collection
            ?.map((item) => item?.product?.id)
            .filter(Boolean) ?? [],
        ),
    );

    const otherProducts = computed(() => {
      const products =
        allProductsResult.value?.products?.edges?.filter(
          (product) =>
            !availableProductIds.value.has(product?.node?.id) &&
            product?.node?.abbreviation !== productAbbr.value &&
            !pendingProductIds.value.has(product?.node?.id),
        ) ?? [];

      return products.slice(0, 5);
    });

    const isPending = computed(() =>
      currentProduct.value?.id ? pendingProductIds.value.has(currentProduct.value.id) : false,
    );

    const hasAccess = computed(() =>
      currentProduct.value?.id ? availableProductIds.value.has(currentProduct.value.id) : false,
    );

    const handleRequestAccess = async () => {
      if (!currentProduct.value?.id) {
        addNotification({
          message: t('pages.productDetails.productNotFound'),
          type: NotificationType.ERROR,
          showIcon: true,
        });
        return;
      }

      const {
        mutate: requestAccess,
        onDone,
        onError,
      } = useCreateProductAccessRequestMutation({
        variables: {
          input: {
            product: currentProduct.value.id,
          },
        },
        update(cache, { data }) {
          const accountQueryResult = cache.readQuery({
            query: GetAccountDocument,
            variables: { id: userStore.userProfile.id },
          }) as { account: GetAccountQuery['account'] };
          if (accountQueryResult?.account && data?.createProductAccessRequest) {
            cache.writeQuery({
              query: GetAccountDocument,
              variables: { id: userStore.userProfile.id },
              data: {
                account: {
                  ...accountQueryResult.account,
                  productAccessRequests: {
                    ...accountQueryResult.account.productAccessRequests,
                    collection: [
                      ...(accountQueryResult.account.productAccessRequests?.collection || []),
                      {
                        id: data?.createProductAccessRequest?.productAccessRequest?.id,
                        product: {
                          id: currentProduct.value?.id,
                          name: currentProduct.value?.name,
                          abbreviation: currentProduct.value?.abbr,
                        },
                        status: 'pending',
                      },
                    ],
                  },
                },
              },
            });
          }
        },
      });

      onDone(() => {
        addNotification({
          message: t('pages.productSwitcher.requestAccessNotification'),
          type: NotificationType.SUCCESS,
          showIcon: true,
        });
      });

      onError((error) => {
        logErrorMessages(error);
      });

      await requestAccess();
    };

    const handleShowDetails = (abbr: string) => {
      productAbbr.value = abbr;
    };

    return {
      currentProduct,
      loadingAccount,
      isPending,
      handleRequestAccess,
      loadingProductInfo,
      otherProducts,
      pendingProductIds,
      hasAccess,
      handleShowDetails,
      images,
    };
  },
});
</script>

<template>
  <div class="product-details">
    <div :class="{ 'back-button--popup': popupMode }" class="product-details__back-button">
      <div class="back-button" @click="$emit('close')">
        <BaseIcon
          data-cy="BackButtonIcon"
          icon-name="chevron--left"
          class="back-button__icon"
          :size="24"
        />
        <span class="back-button__text">
          {{ $t('base.header.backButton') }}
        </span>
      </div>
    </div>

    <div v-if="loadingProductInfo || loadingAccount" class="product-details">
      <BaseLoader />
    </div>

    <div v-else class="product-details__container grid__wrapper">
      <div v-if="currentProduct" class="product-details__main blog-content">
        <div class="blog-post__image">
          <img
            :src="images[currentProduct.abbr as keyof typeof images]"
            :alt="currentProduct.name"
          />
        </div>

        <div v-if="currentProduct" class="blog-post__wrapper">
          <h1 class="blog-post__title">
            {{ $t(`products.product.${currentProduct.abbr}`) }}
          </h1>
          <ViewPostContent v-if="currentProduct.mainText" :content="currentProduct.mainText.html" />

          <div class="product-details__actions">
            <ClickButton
              v-if="!isPending && !hasAccess"
              status="primary"
              :data-cy="`ProductDetails${currentProduct.abbr}RequestAccess`"
              @click="handleRequestAccess"
            >
              {{ $t('pages.productSwitcher.requestAccess') }}
            </ClickButton>
            <ClickButton
              v-else-if="isPending"
              status="disabled"
              :data-cy="`ProductDetails${currentProduct.abbr}Requested`"
            >
              {{ $t('pages.productSwitcher.requestedAccess') }}
            </ClickButton>
          </div>
        </div>
      </div>

      <div v-if="otherProducts.length" class="blog-content__sidebar">
        <h3 class="blog__list-title">
          {{ $t('pages.productSwitcher.discoverOtherProducts') }}
        </h3>
        <ProductCard
          v-for="product in otherProducts"
          :key="product?.node?.id"
          :product="{
            id: product?.node?.id ?? '',
            abbr: product?.node?.abbreviation ?? '',
            name: product?.node?.name ?? '',
            isProductManager: false,
            available: false,
          }"
          :is-client-role="true"
          :data-cy="`OtherProduct${product?.node?.abbreviation}`"
          :is-client-view="false"
          :is-pending="pendingProductIds.has(product?.node?.id)"
          @show-details="handleShowDetails"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/styles/shared/viewPostLetterWebinar.scss';
</style>

<style lang="scss" scoped>
.product-details {
  width: 100%;
  background-color: var(--color-bg-light);

  &__container {
    padding: var(--space-sm) var(--space-md) 0;
  }

  &__back-button {
    padding: var(--space-md) var(--space-md) 0;
  }

  &__actions {
    margin-top: var(--space-lg);
    text-align: center;
  }
}

.back-button {
  @extend %font-button-sm;
  cursor: pointer;
  color: var(--color-secondary);
  display: flex;
  align-items: center;

  &__icon {
    height: remCalc(16);
    width: remCalc(16);
    margin-right: var(--space-6xs);
    stroke: var(--color-secondary);
    stroke-width: 2px;
    stroke-linejoin: round;
  }

  &__text {
    font-weight: 700;
  }

  &--popup {
    padding: 0;
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.blog-content__sidebar h3.blog__list-title {
  padding-top: 0;
}
</style>
