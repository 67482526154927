<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'ViewPostContent',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  setup() {
    const modalImage = ref('');
    const showModal = ref(false);
    const modalElement = ref<HTMLElement | null>(null);

    const openModal = (src: string) => {
      modalImage.value = src;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    onMounted(() => {
      const images = document.querySelectorAll('img');
      images.forEach((img: HTMLImageElement) => {
        img.addEventListener('click', () => openModal(img.src));
      });

      // Move modal to .blog-content so it doesnt dispaly below the menu
      const blogContent = document.querySelector('.blog-content');

      if (blogContent && modalElement.value) {
        blogContent.appendChild(modalElement.value);
      }
    });

    return { modalImage, showModal, openModal, closeModal, modalElement };
  },
});
</script>

<template>
  <div>
    <!--  eslint-disable-next-line -->
    <div data-test="body" class="preview-content" v-html="content" />

    <div ref="modalElement">
      <div v-if="showModal" class="image-modal" @click="closeModal">
        <img :src="modalImage" class="image-modal__content" @click.stop />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.preview-content {
  h1 {
    font-size: 2.625rem;
    line-height: 2.625rem;
    margin-top: 1.3125rem;
    margin-bottom: 1.3125rem;
    font-weight: bold;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 1.3125rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  h3 {
    font-size: 1.125rem;
    line-height: 1.3125rem;
    margin-top: 1.3125rem;
    margin-bottom: 0;
    font-weight: bold;
  }
  h1 span,
  h2 span,
  h3 span {
    font-weight: bold;
  }
  p {
    font-size: 1.125rem;
    margin-bottom: 1.3125rem;
  }
  b,
  strong {
    font-weight: bold;
    * {
      font-weight: bold;
    }
  }
  ul {
    list-style: disc;
    display: block;
    margin-bottom: 1.3125rem;
    li {
      list-style: disc;
      display: list-item;
      margin-left: remCalc(18);
      p {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  ol {
    list-style: decimal;
    display: block;
    margin-bottom: 1.3125rem;
    li {
      list-style: decimal;
      display: list-item;
      margin-left: remCalc(18);
      p {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  mark {
    background-color: var(--color-mark);
    border-radius: remCalc(2);
  }
  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
    margin: 1rem 0 1rem 2rem;
  }
  a {
    text-decoration: underline;
  }

  img {
    max-width: 100%;
    cursor: pointer;
  }
}

.image-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    background-color: white;
  }
}
</style>
