import { computed } from 'vue';
import { ApolloClient, ApolloLink } from '@apollo/client/core';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { graphQLErrorHandler, networkErrorHandler } from '@/utils/apiErrorHandler';
import { headers, endpoints, links, cacheConfig } from '@/config/http';
import { useUserStore } from '@/store';

const getHeaders = computed(() => headers.value);
const userStore = useUserStore();

export const fetchSquidexToken = () =>
  new Promise<string>(() => {
    fetch(`${import.meta.env.VITE_PORTAL_GRAPHQL_URL}/squidex/auth`, {
      headers: {
        Authorization: `Bearer ${userStore.accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }).then(async (res) => {
      const token = await res.json();
      userStore.setSquidexToken(token.token);
    });
  });

const slink = new MultiAPILink({
  endpoints,
  ...links,
  getContext: (endpoint, getCurrentContext) => {
    if (endpoint === 'squidexEndPoint') {
      return {
        headers: {
          Authorization: `Bearer ${userStore.getSquidexToken}`,
          ...getCurrentContext().headers, // this is needed for overriding headers in specific query in components
        },
      };
    }
    return {
      headers: {
        ...getHeaders.value,
        ...getCurrentContext().headers, // this is needed for overriding headers in specific query in components
      },
    };
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  createHttpLink: () => createUploadLink(),
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError && !graphQLErrors?.length) {
    networkErrorHandler();
  } else if (graphQLErrors?.length) {
    // Ability to skip some errors
    const skipErrorPatterns = operation.getContext().skipErrorPatterns || [];

    const errorsToHandle = graphQLErrors.filter(
      (error) => !skipErrorPatterns.some((pattern: string) => error.message.includes(pattern)),
    );

    if (errorsToHandle.length) {
      graphQLErrorHandler(errorsToHandle);
    }
  }
});

const apolloClient = new ApolloClient({
  cache: cacheConfig,
  link: ApolloLink.from([errorLink, slink]),

  connectToDevTools: true,
});

export { apolloClient };
