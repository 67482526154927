import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
import router from '@/router';
import { NotificationType } from '@/types/notification';
import { useUserStore, useNotificationStore } from '@/store';
import { getTranslationTerms } from '@/utils/composable/localeHelper';
import { MAIN_ROUTES } from '@/config/constants/routes';

const getErrorMessage = (error: GraphQLError): string => {
  const violationPath = error?.extensions?.violations?.[0]?.path;
  const violationMessage = error?.extensions?.violations?.[0]?.message;
  const violationFullMessage = violationPath
    ? `${violationPath}: ${violationMessage}`
    : violationMessage;

  return violationMessage ? violationFullMessage : error?.message;
};

const graphQLErrorHandler = (errors: GraphQLErrors): void => {
  const userStore = useUserStore();
  const { addNotification } = useNotificationStore();
  const getStateMsgTerms = getTranslationTerms.bind(null, 'base', 'errorMessages');

  if (errors) {
    errors.forEach(async (error) => {
      switch (error.message) {
        case 'EXPIRED_TOKEN':
          userStore.logout();
          addNotification({
            message: getStateMsgTerms('expiredTokenHeader'),
            description: getStateMsgTerms('expiredToken'),
            type: NotificationType.ALERT,
            showIcon: true,
          });
          await router.push({ name: MAIN_ROUTES.AUTH.name });
          break;
        case 'Expired token.':
          userStore.logout();
          addNotification({
            message: getStateMsgTerms('expiredTokenHeader'),
            description: getStateMsgTerms('expiredToken'),
            type: NotificationType.ALERT,
            showIcon: true,
          });
          await router.push({ name: MAIN_ROUTES.AUTH.name });
          break;
        case 'INVALID_TOKEN':
          userStore.logout();
          addNotification({
            message: getStateMsgTerms('expiredTokenHeader'),
            description: getStateMsgTerms('expiredToken'),
            type: NotificationType.ALERT,
            showIcon: true,
          });
          await router.push({ name: MAIN_ROUTES.AUTH.name });
          break;
        case 'Invalid token.':
          userStore.logout();
          addNotification({
            message: getStateMsgTerms('expiredTokenHeader'),
            description: getStateMsgTerms('expiredToken'),
            type: NotificationType.ALERT,
            showIcon: true,
          });
          await router.push({ name: MAIN_ROUTES.AUTH.name });
          break;
        default:
          addNotification({
            message: getErrorMessage(error),
            type: NotificationType.ERROR,
            showIcon: true,
          });
      }
    });
  }
};

const networkErrorHandler = (): void => {
  const getStateMsgTerms = getTranslationTerms.bind(null, 'base', 'errorMessages');
  const { addNotification } = useNotificationStore();

  addNotification({
    message: getStateMsgTerms('defaultApiNetworkError'),
    type: NotificationType.ERROR,
    showIcon: true,
  });
};

export { graphQLErrorHandler, networkErrorHandler };
