import { acceptHMRUpdate, defineStore } from 'pinia';
import dayjs from 'dayjs';
import { NotificationModel } from '@/types/notification';

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: [] as NotificationModel[],
  }),

  getters: {
    getNotifications(state): NotificationModel[] {
      return state.notifications;
    },
  },

  actions: {
    addNotification(notification: NotificationModel) {
      const recentDuplicate = this.notifications.some((n) => {
        const timeDiff = dayjs().diff(dayjs(n.timeStamp), 'millisecond');
        return n.message === notification.message && timeDiff < 500;
      });

      // filter out duplicate notifications, for example when a token is invalid or connection issues
      if (!recentDuplicate) {
        this.notifications.push({
          ...notification,
          ...(notification.description && {
            description:
              notification.description.length > 180
                ? `${notification.description.substring(0, 177)}...`
                : notification.description,
          }),
          id: Math.random().toString(36).substring(2, 15),
          duration: notification.duration || 9000,
          timeStamp: new Date().toISOString(),
        });
      }
    },
    removeNotification(id: string) {
      this.notifications = this.notifications.filter((notification) => notification.id !== id);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}
