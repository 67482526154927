<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import { useRouter } from 'vue-router';
import ClickButton from '@/components/base/ClickButton.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { tm, rt } from '@/utils/composable/localeHelper';
import AfsLogoGrayscale from '@/assets/images/AFS-Logo-Energy-Grayscale.png';
import { MAIN_ROUTES } from '@/config/constants/routes';

export default defineComponent({
  name: 'PortalFeaturesSidebar',
  components: {
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    ClickButton,
    BaseIcon,
  },
  props: {
    dataCy: {
      type: String,
      required: true,
    },
  },
  emits: ['show-welcome'],
  setup() {
    const router = useRouter();
    const isExpanded = ref(localStorage.getItem('portalFeaturesExpanded') !== 'false');

    const toggleExpanded = () => {
      isExpanded.value = !isExpanded.value;
      localStorage.setItem('portalFeaturesExpanded', isExpanded.value.toString());
    };

    const portalFeatures = computed(() =>
      (
        (tm('pages.productSwitcher.portalFeaturesSidebar') as Array<{
          title: string;
          content: string;
        }>) || []
      ).map((item) => ({
        title: rt(item.title),
        content: rt(item.content),
      })),
    );

    const redirectToPortalFeatures = () => {
      router.push({ name: MAIN_ROUTES.PORTAL_FEATURES.name });
    };

    return {
      portalFeatures,
      isExpanded,
      toggleExpanded,
      AfsLogoGrayscale,
      redirectToPortalFeatures,
    };
  },
});
</script>

<template>
  <div class="introduction-section" :class="{ 'is-collapsed': !isExpanded }">
    <div class="introduction-section__content">
      <div class="introduction-section__header">
        <img
          class="introduction-section__logo"
          :class="{ 'introduction-section__logo--collapsed': !isExpanded }"
          :src="AfsLogoGrayscale"
          alt="AFS Logo"
        />
      </div>

      <div v-if="isExpanded" class="introduction-section__accordion-container">
        <Accordion class="introduction-section__accordion" value="0" :multiple="false">
          <AccordionPanel
            v-for="(item, index) in portalFeatures"
            :key="index"
            :value="String(index)"
          >
            <AccordionHeader>{{ item.title }}</AccordionHeader>
            <AccordionContent>
              <ul class="introduction-section__accordion-content">
                <li class="introduction-section__accordion-content__item">
                  {{ item.content }}
                </li>
              </ul>
            </AccordionContent>
          </AccordionPanel>
        </Accordion>

        <div class="introduction-section__learn-more">
          <ClickButton
            data-cy="learn-more-button"
            status="outlined-primary"
            @click="redirectToPortalFeatures"
          >
            {{ $t('pages.productSwitcher.learnMoreAboutPortal') }}
          </ClickButton>
        </div>
      </div>
    </div>
    <ClickButton
      :data-cy="`${dataCy}toggleIntroductionCollapse`"
      class="introduction-section__toggle"
      status="outlined-primary"
      @click="toggleExpanded"
    >
      <BaseIcon
        v-if="isExpanded"
        :size="32"
        icon-name="chevron--left"
        data-cy="toggle-menu-closed"
      />
      <BaseIcon
        v-if="!isExpanded"
        :size="32"
        icon-name="chevron--right"
        data-cy="toggle-menu-open"
      />
    </ClickButton>
  </div>
</template>

<style lang="scss" scoped>
.introduction-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--space-md);
  width: 100%;
  min-width: 180px;
  height: 100%;
  overflow: hidden;
  transition: width 0.3s ease;

  &.is-collapsed {
    width: 10%;
    padding: remCalc(40) var(--space-xxs);
  }

  @media screen and (min-width: $tablet-minus) {
    width: 30%;
    height: 100vh;
    max-width: 500px;
    padding: var(--space-sm);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__logo {
    width: 100%;
    display: none;
    padding: var(--space-xs);

    &--collapsed {
      padding: 0;
    }

    @media screen and (min-width: $tablet-minus) {
      display: block;
    }
  }

  &__learn-more {
    display: flex;
    justify-content: center;
  }

  &__toggle {
    display: none;
    background: white;

    padding: 0.3rem;
    align-self: center;

    :deep(.btn__text) {
      display: flex;
      align-items: center;
    }

    @media screen and (min-width: $tablet-minus) {
      display: block;
    }
  }

  &__accordion {
    margin-bottom: var(--space-md);
  }

  &__accordion-content {
    margin: 0;
    font-size: var(--text-xs);
    font-weight: 400;

    &__item {
      display: list-item;
      list-style-type: disc;
      margin-left: var(--space-md);
    }
  }

  .btn--outlined-primary.introduction-section__toggle {
    border: none;
  }
}
</style>
