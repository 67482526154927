import { createRouter, createWebHistory, useRoute } from 'vue-router';
import { computed, watchEffect, ref } from 'vue';
import routes from '@/router/routes';
import { MAIN_ROUTES } from '@/config/constants/routes';
import { useUserStore, pinia, useMessageStore, useNotificationStore } from '@/store';
import { onRouteChange } from '@/utils/composable/navigation';
import { UserRole } from '@/types/user';
import { parseJwt } from '@/utils/parseJwt';
import { NotificationType } from '@/types/notification';
import { getTranslationTerms } from '@/utils/composable/localeHelper';

const userStore = useUserStore(pinia);
const { addNotification } = useNotificationStore(pinia);

const BASE_URL = '/'; // TODO move to constants
const router = createRouter({
  history: createWebHistory(BASE_URL),
  routes,
});

const getStateMsgTerms = getTranslationTerms.bind(null, 'base', 'errorMessages');
const messagesStore = useMessageStore();
const isClientRole = computed(() => userStore.getUserRole === UserRole.ROLE_COMPANY_REPRESENTATIVE);
const isSARole = computed(() => userStore.getUserRole === UserRole.ROLE_SUSTAINABILITY_ADVISOR);
const productSelectionRequired = computed(() => isClientRole.value || isSARole.value);
const route = useRoute();
const routeToName = ref<string | unknown>('');
const totalUnreadMessageCount = ref(0);
const isLoggedIn = computed(
  () =>
    userStore.isUserLoggedIn &&
    route?.path !== MAIN_ROUTES.INVITE.path &&
    route?.path !== MAIN_ROUTES.PRODUCT_SWITCHER.path,
);

const messageCount = computed(() => {
  totalUnreadMessageCount.value = messagesStore.getTotalUnreadMessageCount;
  return isLoggedIn.value && totalUnreadMessageCount.value > 0
    ? `(${totalUnreadMessageCount.value})`
    : '';
});

watchEffect(() => {
  if (
    messagesStore.getTotalUnreadMessageCount &&
    messagesStore.getTotalUnreadMessageCount > 0 &&
    (isSARole.value || isClientRole.value)
  ) {
    setInterval(() => {
      document.title = `${import.meta.env.VITE_NAME} | ${routeToName.value} ${messageCount.value}`;
    }, 500);
    setInterval(() => {
      document.title = `${import.meta.env.VITE_NAME} | ${routeToName.value}`;
    }, 1000);
  } else {
    document.title = `${import.meta.env.VITE_NAME} | ${routeToName.value}`;
  }
});
const validToken = () => {
  const decodeToken: { exp: number } = parseJwt(userStore.accessToken as string) as {
    exp: number;
    nbf: number;
  };
  const { exp: expirationTime } = decodeToken;
  const dateNow = new Date();
  if (dateNow > new Date((expirationTime - 60) * 1000)) {
    return false;
  }
  return true;
};

router.beforeEach((routeTo, routeFrom, next) => {
  routeToName.value = routeTo.meta.title;
  const authRequired = routeTo.matched.some((routeLocal) => routeLocal.meta.requiresAuth);
  const redirectToLogin = () => next({ name: MAIN_ROUTES.AUTH.name });
  const redirectToProductSwitcher = () => {
    if (messagesStore.getTotalUnreadMessageCount === 0) {
      document.title = `${import.meta.env.VITE_NAME} | ${MAIN_ROUTES.PRODUCT_SWITCHER.title}`;
    }
    return next({ name: MAIN_ROUTES.PRODUCT_SWITCHER.name });
  };

  if (userStore.isUserLoggedIn) {
    if (!validToken()) {
      userStore.logout();
      addNotification({
        message: getStateMsgTerms('expiredTokenHeader'),
        description: getStateMsgTerms('expiredToken'),
        type: NotificationType.ALERT,
        showIcon: true,
      });
      return redirectToLogin();
    }
    if (
      userStore.isAPIUserRole &&
      routeTo.path !== MAIN_ROUTES.API_PROFILE.path &&
      routeTo.path !== MAIN_ROUTES.INVITE.path
    ) {
      next({ name: MAIN_ROUTES.API_PROFILE.name });
    }
    if (!userStore.selectedProduct && productSelectionRequired.value) {
      if (
        routeTo.path === MAIN_ROUTES.PRODUCT_SWITCHER.path ||
        routeTo.path === MAIN_ROUTES.PORTAL_FEATURES.path ||
        routeTo.name === MAIN_ROUTES.PRODUCT_DETAILS.name
      ) {
        return next();
      }
      return redirectToProductSwitcher();
    }
    return next();
  }
  if (!authRequired) {
    return next();
  }
  return redirectToLogin();
});

router.afterEach(async (to) => {
  onRouteChange(to);
  if (
    (to.path.includes(MAIN_ROUTES.AUTH.path) ||
      to.path.includes(MAIN_ROUTES.RESET_PASSWORD.path)) &&
    localStorage.getItem('user') !== null
  ) {
    await userStore.logout();
  }
});

export default router;
