<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from 'vue';
import { useNotificationStore } from '@/store';
import BaseIcon from '@/components/base/BaseIcon.vue';
import { NotificationModel, NotificationType } from '@/types/notification';

export default defineComponent({
  name: 'Notification',
  components: { BaseIcon },
  props: {
    notification: {
      type: Object as PropType<NotificationModel>,
      required: true,
    },
  },
  setup(props) {
    const store = useNotificationStore();

    const iconName = computed((): string => {
      switch (props.notification.type) {
        case NotificationType.ERROR:
          return 'warning';
        case NotificationType.ALERT:
          return 'warning--alt';
        case NotificationType.SUCCESS:
          return 'checkmark--outline';
        case NotificationType.NOTIFY:
          return 'notification';
        case NotificationType.MESSAGE:
          return 'chat';
        default:
          return 'notification';
      }
    });

    const cypressDataAttribute = `${getCurrentInstance()?.type.name}-${iconName.value}`;

    const closeNotification = (id: string): void => {
      store.removeNotification(id);
    };

    const timeoutId = ref<number>();

    onMounted(() => {
      timeoutId.value = window.setTimeout(() => {
        closeNotification(props.notification.id || '');
      }, props.notification.duration);
    });

    onUnmounted(() => {
      if (timeoutId.value) {
        window.clearTimeout(timeoutId.value);
      }
    });

    return {
      iconName,
      cypressDataAttribute,
      closeNotification,
      store,
    };
  },
});
</script>

<template>
  <div
    v-if="notification"
    id="notification-toast"
    data-unit-test="notification"
    :data-cy="cypressDataAttribute"
    :class="['notification', `notification--${notification?.type}`]"
  >
    <BaseIcon
      v-if="notification.showIcon"
      class="notification__icon"
      :data-cy="iconName"
      :icon-name="iconName"
      @click="() => closeNotification(notification?.id || '')"
    />
    <div class="notification__content">
      <p class="notification__message">
        {{ notification.message }}
      </p>
      <p v-if="notification.description" class="notification__description">
        {{ notification.description }}
      </p>
    </div>
    <BaseIcon
      :class="{ centered: !notification.description && notification.message }"
      :data-cy="'NotificationClose'"
      class="notification__close"
      icon-name="close"
      @click="() => closeNotification(notification?.id || '')"
    />
  </div>
</template>

<style lang="scss" scoped>
.notification {
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  z-index: 8;
  padding: remCalc(12) remCalc(32) remCalc(12) remCalc(12);
  border-radius: remCalc(4);
  color: var(--color-light);
  z-index: 1001;
  transition: 0.3s;
  width: 100%;
  max-width: remCalc(478);
  animation: slideInUp 0.6s ease-in-out;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: var(--space-xxs);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-notification-default);
    z-index: -1;
  }

  svg {
    fill: var(--color-light);
  }

  &--error {
    &::before {
      background-color: var(--color-notification-error);
    }
  }

  &--alert {
    &::before {
      background-color: var(--color-notification-alert);
    }
  }

  &--notify {
    &::before {
      background-color: var(--color-bg);
      border-radius: remCalc(8);
    }
    &::after {
      background-color: var(--color-bg);
    }
    svg {
      fill: var(--color-main);
    }
    .notification__description,
    .notification__message {
      color: var(--color-main);
    }
  }

  &--success {
    &::before {
      background-color: var(--color-notification-success);
    }
  }

  &--message {
    &::before {
      background-color: var(--color-notification-default);
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    margin-right: remCalc(13);
    width: remCalc(23);
    min-width: remCalc(23);
    height: auto;
  }

  &__content {
    min-width: remCalc(400);
  }

  &__message {
    font-weight: 400;
    font-size: remCalc(14);
    line-height: remCalc(16);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: var(--space-5xs);
    }
  }

  &__description {
    font-weight: 300;
    font-size: var(--text-xs);
    line-height: remCalc(16);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }

  &__close {
    min-width: remCalc(24);
    min-height: remCalc(24);
    cursor: pointer;
    animation: closeButtonFadeIn 1.6s ease-in-out;

    &:hover {
      transition: fill 0.3s ease-in-out;
      fill: var(--color-grey);
    }
  }
}

.fade-leave-active {
  animation-duration: 0.3s !important;
}

@keyframes closeButtonFadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
</style>
